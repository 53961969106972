import { defineStore } from 'pinia';

export interface ICursorPosition {
    X: number;
    Y: number;
}

export const useCursorStore = defineStore({
    id: 'cursor',
    state: () => ({
        visible: false,
        interval: 0,
        opacity: 0,
        posX: 0,
        posY: 0,
    }),
    actions: {
        updatePosition(position: ICursorPosition) {
            this.posX = position.X;
            this.posY = position.Y;
        },
        showCursor() {
            clearInterval(this.$state.interval);
            this.$state.visible = true;
            const fadeTimeInMs = 250;

            // gradually change state opacity to 1;
            this.$state.interval = setInterval(() => {
                if (this.$state.opacity <= 1) {
                    this.$state.opacity += 0.01;
                    return;
                }

                clearInterval(this.$state.interval);
                this.$state.opacity = 1;
            }, fadeTimeInMs / 100);
        },
        hideCursor() {
            clearInterval(this.$state.interval);
            const fadeTimeInMs = 250;

            // gradually change state opacity to 0;
            this.$state.interval = setInterval(() => {
                if (this.$state.opacity >= 0) {
                    this.$state.opacity -= 0.01;
                    return;
                }

                clearInterval(this.$state.interval);
                this.$state.visible = false;
            }, fadeTimeInMs / 100);
        },
    },
});
